<template>
  <router-view />
</template>

<style>
* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(206, 206, 206);
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
