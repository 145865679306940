import { db, storage } from "../main";
import {
  getDoc,
  doc,
  query,
  getDocs,
  orderBy,
  collection,
  where,
  addDoc,
  updateDoc
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

export async function logError(errorData, method) {
  try {
    let error = {
      method: method,
      name: errorData.name,
      message: errorData.message,
      on: new Date(),
    };
    await addDoc(collection(db, "Errors"), error);
  } catch (e) {
    // do nothing
  }
}

export async function getBio() {
  let defaultBio =
    "Hi, my name is Stephanie and I am a hairstylist from Fredericksburg, VA. Hair has always been a passion of mine since I was a little girl watching my mother and grandmother style hair in their hair salon, Hazel's Beauty. Although I didn't immediately pursue a career in the hair industry, I still enjoyed braiding and doing my own hair, as well as my friends' hair and my two daughters' hair.  In 2017, I decided to transition my chemically treated hair into its natural state. It was a challenging but rewarding experience as I experimented with various hair products, techniques, and styles. Through this process, I learned the importance of protective styles such as braids, twists, cornrows, and crocheted styles for overall healthy hair.  I have completed training with Bruce Braids Academy and the Marcella Ellis School of Cosmetic Arts and have received certificates for my expertise. I specialize in knotless braids, cornrows, and crochet hair styles. Additionally, for women dealing with hair loss or hair thinning, I offer several healthy protective style options to promote overall hair health.  Hair is not just a job for me, it's a passion. I love helping women feel confident and beautiful in their own skin by enhancing their natural beauty through their hair.";

  let defaultBioImageSrc = null;

  let bio = {
    text: defaultBio,
    image: defaultBioImageSrc,
  };

  try {
    const docRef = doc(db, "Admin", "settings");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      bio.text = data.bio;

      const bioImage = data.bio_imgs.find((obj) => obj.current === true);

      const gsReference = ref(storage, `bio-images/${bioImage.img}`);
      bio.image = await getDownloadURL(gsReference);
    }
  } catch (e) {
    await logError(e, "getBio");
  }

  return bio;
}

export async function getReviews() {
  let reviews = [];

  try {
    const q = query(
      collection(db, "Reviews"),
      where("status", "==", "approved"),
      orderBy("date", "desc")
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;

      reviews.push(data);
    });
  } catch (e) {
    await logError(e, "getReviews");
  }

  return reviews;
}

export async function createRequest(request) {
  try {
    await addDoc(collection(db, "Requests"), request);
    return {
      ok: true,
    };
  } catch (e) {
    await logError(e, "createRequest");
    return {
      ok: false,
    };
  }
}

export async function getReviewRequest(requestId) {
  try {
    const docRef = doc(db, "ReviewRequests", requestId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      if (data.valid) {
        return {
          valid: true,
          id: docSnap.id,
        };
      }
    }
  } catch (e) {
    await logError(e, "getReviewRequest");
  }

  return {
    valid: false,
  };
}

export async function createReview(review, requestId) {
  try {
    let reviewRequest = await getReviewRequest(requestId);

    if (reviewRequest.valid) {
      await updateDoc(doc(db, "ReviewRequests", requestId), {
        valid: false,
      });
      await addDoc(collection(db, "Reviews"), review);

      return {
        ok: true,
      };
    }
  } catch (e) {
    await logError(e, "createReview");
  }

  return {
    ok: false,
  };
}
