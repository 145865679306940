
<template>
  <div class="review">
    <div class="img-container">
      <img
        @click="goToHome"
        src="../assets/blazenbraidslogo.png"
        alt="Blaze-N Braids VA Logo"
      />
    </div>
    <div v-if="validReview && !loadingReview" class="valid-review">
      <span class="review-text">
        <span>Love your hairstyle? We hope to see you soon!</span>
        <span>Thank you for taking the time to fill out this review.</span>
      </span>
      <form
        @submit.prevent="submitReview"
        v-if="!submittingReview"
        class="form-floating review-form"
      >
        <div class="input-div">
          <div class="form-floating">
            <input
              v-model="name"
              type="text"
              class="form-control"
              id="name"
              minlength="3"
              maxlength="70"
              pattern="[a-zA-Z0-9\s',.\-]*"
              required
            />
            <label for="name">First and Last Name</label>
          </div>
        </div>
        <div class="form-floating">
          <textarea
            v-model="review"
            class="form-control"
            id="review"
            style="height: 7rem"
            pattern="[a-zA-Z0-9\s',.\-]*"
            maxlength="120"
            required
          ></textarea>
          <label for="review">Review</label>
        </div>

        <div class="input-div">
          <div class="form-floating">
            <input
              v-model="email"
              type="email"
              class="form-control"
              id="email"
              required
            />
            <label for="email">Email</label>
          </div>
        </div>
        <div class="input-div">
          <div class="form-floating">
            <input
              v-model="phoneNumber"
              type="text"
              class="form-control"
              id="phoneNumber"
              pattern="\d{3}-\d{3}-\d{4}"
              required
            />
            <label for="phoneNumber">Phone Number (XXX-XXX-XXXX)</label>
          </div>
        </div>
        <div class="input-div">
          <button class="btn btn-secondary submit-button" type="submit">
            Submit Review
          </button>
        </div>
      </form>
      <div v-else class="submitting-review">
        <div class="spinner-border text-secondary" role="status"></div>
      </div>
      <div v-if="successfulReview" class="successful-review">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15%"
          height="15%"
          fill="#d46f2c"
          class="bi bi-check-circle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
          />
        </svg>
        <span class="successful-text">
          <span>We received your review.</span>
          <span> Thank you for your feedback! </span>
        </span>
      </div>
    </div>
    <div v-else-if="!validReview && !loadingReview" class="invalid-review-link">
      <span
        >Uh Oh! This review link has expired. If you still would like to submit
        a review,
        <span @click="scrollToFooter" class="orange-text">connect with us</span>
        to request to submit a review!</span
      >
    </div>
    <div v-if="loadingReview" class="loading-review">
      <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <footer id="footer">
      <span class="footer-policies">
        <span>Privacy Policy</span> | <span>Do Not Share My Information</span> |
        <span>Conditions of Use</span></span
      >
      <span class="footer-owner-text"> Stephanie Samuel, VA Braider. </span>
      <div class="contact-info">
        <a href="tel:+15405381599" style="margin-right: 5px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="lightgray"
            class="bi bi-telephone-fill"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
            />
          </svg>
        </a>
        <a href="sms:+15405381599">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="lightgray"
            class="bi bi-chat-dots-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            />
          </svg>
        </a>
        <span class="small-text contact-text">+1 (540) 538-1599</span>
      </div>
      <a href="mailto:hello@blazenbraidsva.com" class="w-100 footer-contact">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="lightgray"
          class="bi bi-envelope-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
          />
        </svg>
        <span class="small-text contact-text">hello@blazenbraidsva.com</span>
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100089549341625"
        class="w-100 footer-contact"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="lightgray"
          class="bi bi-facebook"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
          />
        </svg>
        <span class="small-text contact-text">Connect With Us</span>
      </a>
      <span class="small-text">
        Copyright &copy; {{ getCurrentYear() }} Blaze-N Braids, LLC. All rights
        reserved.
      </span>
      <span class="small-text">Powered by The NixTech Company, LLC</span>
    </footer>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { getReviewRequest, createReview } from "@/composables/settings";
import { ref, onBeforeMount } from "vue";

export default {
  props: ["query"],
  setup(props) {
    const router = useRouter();
    const validReview = ref(false);
    const name = ref("");
    const review = ref("");
    const email = ref("");
    const phoneNumber = ref("");
    const submittingReview = ref(false);
    const successfulReview = ref(false);
    const loadingReview = ref(true);
    const showToast = ref(false);

    let reviewRequest = null;

    onBeforeMount(async () => {
      if (props.query !== undefined) {
        reviewRequest = await getReviewRequest(props.query);

        if (reviewRequest.valid) {
          validReview.value = true;
        }
      }

      loadingReview.value = false;
    });

    function getCurrentYear() {
      return new Date().getFullYear();
    }

    function scrollToFooter() {
      let footer = document.getElementById("footer");
      footer.scrollIntoView({ behavior: "smooth" });
    }

    function goToHome() {
      router.push("/");
    }

    async function submitReview() {
      if (reviewRequest === null) {
        alert("Sorry something went wrong!");
        router.go(0);
        return;
      }

      if (name.value.trim().length <= 0) {
        alert("Please enter your first and last name.");
        return;
      }

      if (review.value.trim().length <= 0) {
        alert("Please provide some details in your review.");
        return;
      }

      if (email.value.trim().length <= 0) {
        alert("Please enter your email address for your review.");
        return;
      }

      if (phoneNumber.value.trim().length <= 0) {
        alert("Please enter your phone number for your review.");
        return;
      }

      let reviewData = {
        name: name.value,
        text: review.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        status: "pending",
        date: new Date(),
      };

      submittingReview.value = true;

      let reviewResponse = await createReview(reviewData, props.query);

      if (reviewResponse.ok) {
        successfulReview.value = true;

        setTimeout(() => {
          name.value = "";
          email.value = "";
          phoneNumber.value = "";
          review.value = "";
          successfulReview.value = false;
          submittingReview.value = false;
          router.push("/");
        }, 2500);
      } else {
        alert("Sorry, something went wrong!");
        router.push("/");
      }
    }

    return {
      getCurrentYear,
      scrollToFooter,
      goToHome,
      validReview,
      submitReview,
      loadingReview,
      name,
      email,
      phoneNumber,
      review,
      successfulReview,
      submittingReview,
    };
  },
};
</script>

<style scope>
.review {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  background: rgb(235, 235, 235);
  position: relative;
}

.img-container {
  height: 30vh;
  margin-top: 5rem;
}

.img-container img {
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.valid-review,
.invalid-review-link,
.loading-review {
  width: 100%;
  min-height: 70vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative;
}

.loading-review {
  align-items: center;
}

.submitting-review,
.successful-review {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 60vh;
  background: rgb(235, 235, 235);
  padding-top: 5rem;
}

.submitting-review {
  justify-content: center;
  align-items: flex-start;
}

.successful-review {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  flex-flow: column wrap;
}

.successful-text {
  width: 100%;
  font-size: calc(14px + 0.1vw);
  color: #83441b;
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
}

.successful-text span {
  width: 100%;
}

.schedule-form .input-div {
  margin-top: 8px;
  margin-bottom: 8px;
}

.valid-review {
  justify-content: flex-start;
  flex-flow: column wrap;
}

.invalid-review-link {
  padding: 1rem 5rem;
  font-size: calc(16px + 0.1vw);
  margin-top: 5rem;
  min-height: 40vh;
}

.orange-text {
  color: #a55622;
  font-weight: bold;
  cursor: pointer;
}

.review-text {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  margin: 20px auto;
  font-size: calc(16px + 0.1vw);
}

.review-text span {
  width: 100%;
}

.review-form {
  width: 100%;
  position: relative;
  padding: 2rem;
  margin: 0 auto;
  max-width: 35rem;
}

.review-form .input-div {
  margin-top: 8px;
  margin-bottom: 8px;
}

.review-form textarea {
  resize: none;
}
.review-form .submit-button {
  margin-top: 20px;
}

.review-form .form-floating input,
.review-form .form-floating textarea,
.review-form .form-floating select {
  background: rgb(219, 219, 219);
  font-size: 12px;
  text-align: start;
}

.review-form .form-floating input:focus,
.review-form .form-floating textarea:focus,
.review-form .form-floating select:focus {
  outline: none;
  box-shadow: unset;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.9rem;
}

.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: rgb(219, 219, 219);
}

/* FOOTER */
footer {
  height: auto;
  background: #a55622;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  letter-spacing: 1.5px;
}

footer a {
  color: white;
}

.footer-policies {
  text-align: center;
  width: 100%;
  font-size: 11px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row wrap;
}

.footer-policies span {
  cursor: pointer;
  margin: 0 2px;
}
.footer-contact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 7px;
}

.contact-info {
  display: flex;
  justify-content: flex-start;
}
.small-text {
  font-size: 12px;
  margin-top: 3px;
  width: 100%;
}
.contact-text {
  margin-left: 9px;
}

.footer-owner-text {
  font-size: 14px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 787px) {
  .img-container {
    height: 20vh;
  }

  .footer-policies {
    font-size: 9px;
  }

  .invalid-review-link {
    padding: 1rem 2rem;
  }
}
</style>