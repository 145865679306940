import { ref, getDownloadURL } from "firebase/storage";
import { collection, query, getDocs, getDoc, doc } from "firebase/firestore";
import { db, storage } from "../main";

export async function getGalleryPhotos() {
  let urls = [];
  let imgSources = [];

  try {
    const q = query(collection(db, "Gallery"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const dataUrls = data.urls;

      if (dataUrls !== undefined) {
        const groupUrls = []

        dataUrls.forEach((url) => {
          if (url.trim().length > 0) {
            groupUrls.push(`${doc.id}/${url}`)
          }
        });
        
        if (groupUrls.length > 0) {
          urls.push(groupUrls);
        }
      }
    });

    for (const set of urls) {
      let urlSet = []
      for (const url of set) {
        if (url.trim().length > 0) {
          const gsReference = ref(storage, url);
          const downloadUrl = await getDownloadURL(gsReference)

          if (downloadUrl !== undefined) {
            urlSet.push(downloadUrl);
          }
        }
      }

      if (urlSet.length > 0) {
        imgSources.push(urlSet);
      }
    }
  } catch (error) {
    console.log(error);
  }

  return imgSources;
}

export async function getHairTipPhotos() {
  let imgSources = [];

  try {
    const docRef = doc(db, "Admin", "tips");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const tips = data.tips;

      for (const image of tips) {
        const gsReference = ref(storage, `hair-tips/${image}`);

        imgSources.push(await getDownloadURL(gsReference));
      }

    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.log(error);
  }

  return imgSources;
}
