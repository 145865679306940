<template>
  <div class="home">
    <div class="logo-container">
      <img src="../assets/blazenbraidslogo.png" alt="Blaze-N Braids VA Logo" />
    </div>
    <!-- MAIN -->
    <div class="main">
      <section class="main-container">
        <div class="main-location">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-geo-alt-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
            />
          </svg>
          <span>Stafford, Virginia</span>
        </div>
        <h1 class="main-text">BLAZE-N BRAIDS</h1>
        <div class="main-pictures">
          <div class="picture first">
            <img src="../assets/style-1.png" alt="hair style" />
          </div>
          <div class="picture second">
            <img src="../assets/style-4.png" alt="hair style" />
          </div>
          <div class="picture third">
            <img src="../assets/style-2.png" alt="hair style" />
          </div>
          <div class="picture fourth">
            <img src="../assets/style-5.png" alt="hair style" />
          </div>
        </div>
        <button
          @click="scrollToSchedule"
          class="btn schedule-btn"
          type="button"
        >
          Schedule An Appointment
        </button>
      </section>
      <div class="transparent"></div>
    </div>

    <!-- SCHEDULE -->
    <section class="schedule" id="schedule-section">
      <header>SCHEDULE</header>
      <form class="form-floating schedule-form">
        <div class="input-div">
          <div class="form-floating">
            <input
              v-model="name"
              @input="checkRequest"
              type="text"
              class="form-control"
              id="name"
              minlength="3"
              maxlength="70"
              pattern="[a-zA-Z0-9\s',.\-]*"
              required
            />
            <label for="name">First and Last Name*</label>
          </div>
        </div>
        <div class="form-floating">
          <textarea
            v-model="hairstyle"
            @input="checkRequest"
            class="form-control"
            id="hairstyle"
            style="height: 5rem"
            pattern="[a-zA-Z0-9\s',.\-]*"
            maxlength="100"
            required
          ></textarea>
          <label for="hairstyle">Desired Hairstyle*</label>
        </div>
        <div class="input-div">
          <div class="form-floating">
            <input
              v-model="requestedDate"
              @input="checkRequest"
              type="date"
              class="form-control"
              id="date"
              :min="minScheduleDate"
              required
            />
            <label for="date">Date*</label>
          </div>
        </div>
        <div class="input-div">
          <div class="form-floating">
            <input
              v-model="requestedTime"
              @input="checkRequest"
              type="time"
              class="form-control"
              id="time"
              min="08:00"
              max="20:00"
              required
            />
            <label for="time">Time*</label>
          </div>
        </div>
        <div class="input-div">
          <div class="form-floating">
            <select
              v-model="preferredContactMethod"
              @change="checkRequest"
              class="form-select"
              id="contactMethod"
              aria-label="Preferred Contact Method"
              required
            >
              <option value="Text">Text</option>
              <option value="Call">Call</option>
              <option value="Email">Email</option>
            </select>
            <label for="contactMethod">Preferred Contact Method*</label>
          </div>
        </div>
        <div class="input-div">
          <div class="form-floating">
            <input
              v-model="email"
              @input="checkRequest"
              type="email"
              class="form-control"
              id="email"
              required
            />
            <label for="email">Email*</label>
          </div>
        </div>
        <div class="input-div">
          <div class="form-floating">
            <input
              v-model="phoneNumber"
              @input="checkRequest"
              type="text"
              class="form-control"
              id="phoneNumber"
              required
            />
            <label for="phoneNumber">Phone Number*</label>
          </div>
        </div>
        <div v-if="validRequest" class="input-div">
          <a
            :href="`mailto:stephanie@blazenbraids.com?subject=Blaze-N Braids Appointment: ${name}&body=${getRequest()}`"
            class="btn btn-secondary submit-button"
          >
            Email
          </a>
          <a
            :href="`sms:+15405381599;&body=${getRequest()}`"
            class="btn btn-secondary submit-button"
          >
            Text
          </a>
          <a href="tel:+5405381599" class="btn btn-secondary submit-button">
            Call
          </a>
        </div>
      </form>
    </section>

    <!-- GALLERY -->
    <section v-if="loadingPhotos" class="loading photos">
      <div class="placeholder-glow">
        <div class="placeholder"></div>
      </div>
    </section>
    <section
      v-if="(photos.length > 0 || firstPhotos) && !loadingPhotos"
      class="gallery"
    >
      <h2 class="gallery-text">GALLERY</h2>
      <div class="gallery-container">
        <div id="galleryIndicators" class="carousel slide" data-ride="true">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="carousel-img-container">
                <img
                  v-for="(firstUrl, firstUrlIndex) in firstPhotos"
                  :key="firstUrlIndex"
                  :src="firstUrl"
                  alt="gallery picture"
                />
              </div>
            </div>
            <div
              v-for="(groups, index) in photos"
              :key="index"
              class="carousel-item"
            >
              <div class="carousel-img-container">
                <img
                  v-for="(url, urlIndex) in groups"
                  :key="urlIndex"
                  :src="url"
                  alt="gallery picture"
                />
              </div>
            </div>
          </div>
          <button
            v-if="photos.length > 0"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#galleryIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            v-if="photos.length > 0"
            class="carousel-control-next"
            type="button"
            data-bs-target="#galleryIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="gallery-bg"></div>
      <div class="gallery-transparent"></div>
    </section>

    <!-- REVIEWS -->
    <section v-if="loadingReviews" class="loading reviews">
      <div class="placeholder-glow">
        <div class="placeholder"></div>
      </div>
    </section>
    <section
      v-if="(reviews.length > 0 || firstReview) && !loadingReviews"
      class="reviews"
    >
      <header class="reviews-header">CUSTOMER GRATIFICATION</header>
      <div class="reviews-container">
        <div id="reviewsIndicators" class="carousel slide" data-ride="true">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="review-container">
                <span> {{ firstReview.name }}</span>
                <span> {{ firstReview.text }}</span>
              </div>
            </div>
            <div
              v-for="(review, index) in reviews"
              :key="index"
              class="carousel-item"
            >
              <div class="review-container">
                <span> {{ review.name }}</span>
                <span> {{ review.text }}</span>
              </div>
            </div>
          </div>
          <button
            v-if="reviews.length > 0"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#reviewsIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            v-if="reviews.length > 0"
            class="carousel-control-next"
            type="button"
            data-bs-target="#reviewsIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>

    <!-- ABOUT ME -->
    <section v-if="loadingBio" class="loading bio">
      <div class="placeholder-glow">
        <div class="placeholder"></div>
      </div>
    </section>
    <section v-if="bio && !loadingBio" class="about-me">
      <div class="about-me-img-container">
        <img
          v-if="bio.image === null"
          src="../assets/me.png"
          alt="your stylist"
        />
        <img v-else :src="bio.image" alt="your stylist" />
      </div>
      <div class="about-me-bio">
        <header>STEPHANIE SAMUEL</header>
        <div class="bio-truncated">
          <div v-if="showingFullBio" class="bio-text">{{ bio.text }}</div>
          <span v-else class="bio-text"> {{ bioTruncated }}...</span>
          <div @click="toggleBio" class="show-bio-more-less">
            <span
              >Show <span v-if="showingFullBio">Less</span>
              <span v-else>More</span></span
            >
          </div>
        </div>
      </div>
      <div class="gallery-transparent"></div>
    </section>

    <!-- HAIR TIPS -->
    <section v-if="loadingTips" class="loading tips">
      <div class="placeholder-glow">
        <div class="placeholder"></div>
      </div>
    </section>
    <section v-if="tipsPhotos.length > 0 && !loadingTips" class="hair-tips">
      <header class="hair-tips-header">POWERFUL HAIR TIPS</header>
      <div class="hair-tips-container">
        <img
          v-for="(tip, index) in tipsPhotos"
          :key="index"
          :src="tip"
          alt="hair tip"
        />
      </div>
    </section>

    <!-- FOOTER -->
    <footer>
      <span class="footer-policies">
        <span>Privacy Policy</span> | <span>Do Not Share My Information</span> |
        <span>Conditions of Use</span></span
      >
      <span class="footer-owner-text"> Stephanie Samuel, VA Braider. </span>
      <div class="contact-info">
        <a href="tel:+15405381599" style="margin-right: 5px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="lightgray"
            class="bi bi-telephone-fill"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
            />
          </svg>
        </a>
        <a href="sms:+15405381599">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="lightgray"
            class="bi bi-chat-dots-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            />
          </svg>
        </a>
        <span class="small-text contact-text">+1 (540) 538-1599</span>
      </div>
      <a href="mailto:stephanie@blazenbraids.com" class="w-100 footer-contact">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="lightgray"
          class="bi bi-envelope-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
          />
        </svg>
        <span class="small-text contact-text">stephanie@blazenbraids.com</span>
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100089549341625"
        class="w-100 footer-contact"
        target="_blank" rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="lightgray"
          class="bi bi-facebook"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
          />
        </svg>
        <span class="small-text contact-text">Connect With Us</span>
      </a>
      <span class="small-text">
        Copyright &copy; {{ getCurrentYear() }} Blaze-N Braids, LLC. All rights
        reserved.
      </span>
      <span class="small-text">Powered by The NixTech Company, LLC</span>
    </footer>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import { getGalleryPhotos, getHairTipPhotos } from "@/composables/photos";
import { getBio, getReviews } from "@/composables/settings";

export default {
  name: "HomeView",
  setup() {
    const photos = ref([]);
    const firstPhotos = ref(null);
    const name = ref("");
    const hairstyle = ref("");
    const preferredContactMethod = ref("Text");
    const requestedDate = ref("");
    const requestedTime = ref("");
    const email = ref("");
    const phoneNumber = ref("");
    const tipsPhotos = ref([]);
    const bio = ref(null);
    const bioTruncated = ref(null);
    const reviews = ref([]);
    const firstReview = ref(null);
    const router = useRouter();
    const validRequest = ref(false);

    const now = new Date();
    const tomorrow = new Date(now);
    const minScheduleDate = ref(tomorrow.toISOString().split("T")[0]);

    const loadingPhotos = ref(true);
    const loadingBio = ref(true);
    const loadingReviews = ref(true);
    const loadingTips = ref(true);
    const showingFullBio = ref(false);
    const submittingRequest = ref(false);
    const successfulRequest = ref(false);

    let windowWidth = window.innerWidth;

    onBeforeMount(async () => {
      let galleryPhotos = await getGalleryPhotos();

      photos.value = galleryPhotos.slice(1);

      firstPhotos.value = galleryPhotos.shift();

      loadingPhotos.value = false;
      tipsPhotos.value = await getHairTipPhotos();

      loadingTips.value = false;

      let currentBio = await getBio();

      setBio(currentBio);
      bio.value = currentBio;

      loadingBio.value = false;

      let approvedReviews = await getReviews();

      reviews.value = approvedReviews.slice(1);
      firstReview.value = approvedReviews.shift();
      loadingReviews.value = false;
    });

    function setBio(currentBio) {
      showingFullBio.value =
        windowWidth <= 768
          ? currentBio.text.length < 500
          : currentBio.text.length < 700;
      bioTruncated.value =
        windowWidth <= 768
          ? currentBio.text.slice(0, 500)
          : currentBio.text.slice(0, 700);
    }

    function scrollToSchedule() {
      let scheduleSection = document.getElementById("schedule-section");
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }

    function militaryTo12Hour(militaryTime) {
      let hour = parseInt(militaryTime.substring(0, 2), 10);
      let minute = militaryTime.substring(2);

      // Determine AM or PM
      let period = hour >= 12 ? "PM" : "AM";
      hour = hour % 12;
      hour = hour ? hour : 12;

      if (minute.length === 1) {
        minute = "0" + minute;
      }

      // Format the time
      let twelveHourTime = hour + minute + " " + period;

      return twelveHourTime;
    }

    function autoResizeTextArea(element) {
      element.style.height = "auto";
      element.style.height = element.scrollHeight + "px";
    }

    function checkRequest() {
      if (
        name.value.trim().length <= 0 ||
        hairstyle.value.trim().length <= 0 ||
        time.value.trim().length <= 0 ||
        requestedDate.value.trim().length <= 0 ||
        email.value.trim().length <= 0 ||
        phoneNumber.value.trim().length <= 0
      ) {
        validRequest.value = false;
        return;
      }

      validRequest.value = true;
    }

    function getRequest() {
      let currentDate = new Date();
      let appointmentDate = new Date(date.value.trim());

      currentDate.setUTCHours(0, 0, 0, 0);
      appointmentDate.setUTCHours(0, 0, 0, 0);

      let message = `Hey there, it's ${name.value}! Are you available ${
        currentDate.getTime() === appointmentDate.getTime()
          ? "today"
          : "on " + new Date(requestedDate.value).toUTCString()
      } at ${militaryTo12Hour(
        requestedTime.value
      )} for an appointment? My desired hairstyle: ${
        hairstyle.value
      }. My email address is ${email.value} and my phone number is ${
        phoneNumber.value
      }. My preferred contact method is ${
        preferredContactMethod.value
      }. Thank you!\n`;

      return message;
    }

    function getCurrentYear() {
      return new Date().getFullYear();
    }

    function toggleBio() {
      showingFullBio.value = !showingFullBio.value;
    }

    return {
      photos,
      firstPhotos,
      scrollToSchedule,
      minScheduleDate,
      name,
      hairstyle,
      preferredContactMethod,
      requestedDate,
      requestedTime,
      email,
      phoneNumber,
      autoResizeTextArea,
      tipsPhotos,
      bio,
      getCurrentYear,
      reviews,
      firstReview,
      loadingBio,
      loadingPhotos,
      loadingTips,
      loadingReviews,
      bioTruncated,
      toggleBio,
      showingFullBio,
      submittingRequest,
      successfulRequest,
      validRequest,
      checkRequest,
      getRequest,
    };
  },
};
</script>

<style scoped>
.home {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column wrap;
  font-family: "Chivo Mono", monospace;
  position: relative;
}

.logo-container {
  position: absolute;
  top: 0;
  left: 1rem;
  height: calc(6rem + 1vw);
  padding: 10px;
  z-index: 10;
}

.logo-container img {
  max-height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 1px white);
}

/* MAIN */
.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: black;
}

.main-container,
.main-pictures {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.main-pictures {
  flex-flow: row nowrap;
  margin-top: 8rem;
}

.picture {
  min-width: 20rem;
  min-height: 20rem;
  width: 50vh;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.picture.first,
.picture.third {
  bottom: 20%;
  align-self: flex-end;
}

.picture.second,
.picture.fourth {
  bottom: 8%;
}

.picture.first {
  left: 6%;
}

.picture.second {
  left: 2%;
  z-index: 2;
}

.picture.third {
  z-index: 3;
  right: 2%;
}

.picture.fourth {
  right: 6%;
  z-index: 4;
}

.picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.schedule-btn,
.main-text,
.main-location {
  position: absolute;
}

.main-location {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  height: 2rem;
  color: white;
  z-index: 10;
  top: 5%;
  right: 4%;
  font-family: "Lato", sans-serif;
  font-weight: bold;
}

.main-location span {
  margin-left: 6px;
}

.schedule-btn {
  z-index: 10;
  background: white;
  color: #ed7d31;
  bottom: 25%;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.schedule-btn:hover {
  border: 1px solid #d46f2c;
  background: white;
}

.schedule-btn:focus {
  background: white;
  border-color: #d46f2c;
  color: #d46f2c;
}
.main-text {
  color: white;
  z-index: 10;
  font-size: 10.5vw;
  white-space: nowrap;
  top: 30%;
}
.transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ed7d31;
  z-index: 8;
  opacity: 0.6;
}

.loading {
  transition: opacity 0.3s ease, height 0.3s ease;
  transition: display 0.3s ease, height 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder-glow {
  width: 100%;
  height: 100%;
  padding: 8rem 5rem;
}

.placeholder {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  min-height: 30vh;
  max-width: 45rem;
  margin: 0 auto;
}
/* SCHEDULE */
.schedule {
  width: 100%;
  background: rgb(235, 235, 235);
  position: relative;
  min-height: 20vh;
}

.schedule header {
  position: absolute;
  left: 5%;
  top: 5%;
  font-size: 2rem;
  font-family: "Chivo Mono", monospace;
  color: #a55622;
  z-index: 3;
}

.schedule-form,
.submitting-request,
.successful-request {
  margin: 4rem auto;
  margin-top: 6rem;
  width: 80%;
  max-width: 35rem;
}

.submitting-request,
.successful-request {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  background: rgb(235, 235, 235);
}

.successful-request {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  flex-flow: column wrap;
}

.successful-text {
  width: 100%;
  font-size: calc(14px + 0.1vw);
  color: #83441b;
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
}

.successful-text span {
  width: 100%;
}

.schedule-form .input-div {
  margin-top: 8px;
  margin-bottom: 8px;
}

.schedule-form textarea {
  resize: none;
}
.schedule-form .submit-button {
  margin-top: 20px;
}

.schedule .form-floating input,
.schedule .form-floating textarea,
.schedule .form-floating select {
  background: rgb(219, 219, 219);
  font-size: 12px;
  text-align: start;
}

.schedule .form-floating input:focus,
.schedule .form-floating textarea:focus,
.schedule .form-floating select:focus {
  outline: none;
  box-shadow: unset;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.9rem;
}

.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: rgb(219, 219, 219);
}

.submit-button {
  margin: 0 5px;
}
/* GALLERY */
.gallery,
.loading.photos {
  height: 95vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.loading.photos {
  background: rgb(237, 125, 49, 0.8);
}

.gallery-text {
  position: absolute;
  left: 5%;
  top: 5%;
  font-size: 2rem;
  font-family: "Chivo Mono", monospace;
  color: #a55622;
  z-index: 3;
}

.gallery-container {
  height: 100%;
  width: 100%;
  padding-top: 4.2rem;
  padding-bottom: 20px;
  z-index: 10;
  position: relative;
}

.gallery-container .carousel,
.gallery-container .carousel .carousel-inner {
  height: 100%;
  width: 100%;
}

.gallery-container .carousel .carousel-item {
  height: 100%;
}

.gallery-container .carousel .carousel-img-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
}

.gallery-container .carousel .carousel-item img {
  object-fit: contain;
  max-height: 80%;
  margin: 0 10px;
  border-radius: 10px;
}

.gallery-bg {
  position: absolute;
  bottom: 0;
  left: -10rem;
  height: 100%;
  width: 200%;
  margin: 0 auto;
  background: url(../assets/flames.png) repeat-x,
    url(../assets/flames.png) repeat-x;
  background-position: bottom;
  background-size: contain;
  z-index: 8;
}

.gallery-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ed7d31;
  opacity: 0.6;
  z-index: 9;
}

.reviews,
.loading.reviews {
  width: 100%;
  min-height: 70vh;
  background: rgb(235, 235, 235);
  position: relative;
  display: flex;
  flex-flow: row wrap;
}

.reviews-header {
  position: absolute;
  left: 5%;
  top: 5%;
  font-size: 2rem;
  font-family: "Chivo Mono", monospace;
  color: #a55622;
  z-index: 3;
}

.reviews-container {
  width: 100%;
  padding-top: 4.2rem;
  padding-bottom: 20px;
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.reviews-container .carousel,
.reviews-container .carousel .carousel-inner {
  height: 100%;
  width: 100%;
}

.reviews-container .carousel .carousel-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.review-container {
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  background: #d7d7d7;
  border-radius: 8px;
}

.review-container span {
  width: 100%;
  font-size: 14px;
  font-style: italic;
}

.review-container span:first-of-type {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.review-container span:last-of-type {
  white-space: pre-wrap;
}

/* ABOUT ME */
.about-me,
.loading.bio {
  width: 100%;
  min-height: 100vh;
  background: rgb(216, 216, 216);
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-family: "Chivo Mono", monospace;
  color: rgb(77, 77, 77);
}

.about-me-bio {
  flex: 1;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  padding-top: 0;
}

.about-me-bio header {
  text-align: start;
  width: 100%;
  font-size: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 10px;
}

.bio-text {
  height: 80%;
  font-size: 16px;
  text-align: left;
  white-space: pre-wrap;
  margin-top: 5px;
}

.bio-truncated {
  text-align: left;
}

.bio-truncated .show-bio-more-less {
  margin: 1.5rem 0;
  background: #c0682d;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: calc(14px + 0.2vw);
  color: #2b1506;
  cursor: pointer;
}

.about-me-img-container {
  width: 50%;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 0;
}

.about-me-img-container img {
  height: 75%;
  max-height: 20rem;

  object-fit: contain;
  margin-left: 4rem;
  border-radius: 1%;
  box-shadow: 0 0 4px rgba(77, 77, 77, 0.5);
}

/* HAIR TIPS */
.hair-tips,
.loading.tips {
  width: 100%;
  min-height: 50vh;
  background: rgb(235, 235, 235);
  position: relative;
  display: flex;
  align-items: center;
  padding: 2rem;
  padding-top: 6rem;
}

.hair-tips-header {
  position: absolute;
  left: 5%;
  top: 5%;
  font-size: 2.5vw;
  font-family: "Chivo Mono", monospace;
  color: #a55622;
  z-index: 3;
}

.hair-tips-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hair-tips-container::-webkit-scrollbar {
  display: none;
}

.hair-tips-container img {
  height: 90%;
  max-height: 20rem;
  margin: 0 10px;
  border-radius: 10px;
}

/* FOOTER */
footer {
  height: auto;
  background: #a55622;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  letter-spacing: 1.5px;
}

footer a {
  color: white;
  text-decoration: none;
}

.footer-policies {
  text-align: center;
  width: 100%;
  font-size: 11px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row wrap;
}

.footer-policies span {
  cursor: pointer;
  margin: 0 2px;
}
.footer-contact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 7px;
}

.contact-info {
  display: flex;
  justify-content: flex-start;
}

.small-text {
  font-size: 12px;
  margin-top: 3px;
  width: 100%;
}
.contact-text {
  margin-left: 9px;
}

.footer-owner-text {
  font-size: 14px;
  margin-bottom: 5px;
}

@media (max-width: 767px), (min-width: 768px) and (orientation: portrait) {
  .main {
    height: 85vh;
  }

  .footer-policies {
    font-size: 9px;
  }

  .main-pictures {
    margin-top: 4rem;
  }

  .picture.first,
  .picture.third {
    bottom: 15%;
    align-self: center;
    left: 0;
  }

  .picture.second,
  .picture.fourth {
    bottom: 8%;
    left: 0;
  }

  .main-text {
    font-size: 16vw;
    text-align: center;
    white-space: unset;
  }

  .main-location {
    top: 3%;
    right: 3%;
    font-size: 1rem;
  }

  .schedule-btn {
    bottom: 20%;
    font-size: 1rem;
    padding: 10px 10px;
  }

  .schedule header {
    font-size: 1.8rem;
  }

  .gallery-text,
  .reviews-header,
  .hair-tips-header {
    font-size: 1.7rem;
    text-align: start;
  }

  .gallery-container {
    padding-top: 2rem;
    padding-bottom: 10px;
  }

  .gallery-container .carousel .carousel-img-container {
    flex-flow: column wrap;
  }

  .gallery-container .carousel .carousel-item img {
    max-height: 35%;
    margin: 10px;
  }

  .about-me {
    flex-flow: column nowrap;
  }

  .about-me,
  .loading.bio {
    min-height: 90vh;
  }

  .about-me-bio header {
    font-size: 2rem;
  }

  .about-me-bio {
    padding: 1.5rem;
    justify-content: flex-start;
  }

  .about-me-img-container {
    width: 100%;
  }

  .review-container {
    margin: 1rem;
  }
  .about-me-img-container img {
    margin: 0 auto;
    height: 50%;
    max-height: calc(20rem + 5vh);
  }

  .hair-tips,
  .loading.tips {
    padding-top: 4rem;
    padding-bottom: 1.5rem;
    min-height: 45vh;
  }

  .hair-tips-container img {
    max-height: calc(15rem + 2vh);
  }
}

@media (min-width: 768px) and (orientation: landscape) {
  .main-pictures {
    margin-top: 8rem;
  }

  .picture.first,
  .picture.third {
    bottom: 20%;
    align-self: flex-end;
    left: 6%;
  }

  .picture.second,
  .picture.fourth {
    bottom: 8%;
    left: 2%;
  }

  .main-text {
    font-size: 8.5vw;
  }

  .main-location {
    top: 5%;
    right: 4%;
    font-size: calc(1rem + 0.15vw);
  }

  .schedule-btn {
    bottom: 25%;
    font-size: calc(1.1rem + 0.2vw);
    padding: 10px 20px;
  }

  .schedule-form {
    max-width: calc(30rem + 5vw);
  }

  .schedule .form-floating {
    min-height: calc(5rem + 0.3vh);
  }

  .schedule .form-floating input,
  .schedule .form-floating textarea,
  .schedule .form-floating select {
    font-size: calc(12px + 0.2vw);
    min-height: calc(5rem + 0.3vh);
  }

  .schedule header,
  .reviews-header {
    font-size: 2.5vw;
  }

  .review-container {
    max-width: calc(40rem + 5vw);
  }

  .review-container span:first-of-type {
    font-size: calc(16px + 0.1vw);
  }

  .review-container span:last-of-type {
    font-size: calc(14px + 0.1vw);
  }

  .about-me-bio {
    padding-left: 1rem;
    padding-right: 2rem;
  }

  .about-me-bio .bio-text {
    font-size: calc(0.9rem + 0.1vw);
  }

  .about-me-img-container {
    justify-content: flex-end;
    padding: 0;
    padding-right: 3rem;
  }
  .about-me-img-container img {
    max-height: calc(25rem + 8vh);
  }
  .gallery-text {
    font-size: 2.5vw;
  }

  .gallery-container {
    padding-top: 4.2rem;
    padding-bottom: 20px;
  }

  .gallery-container .carousel .carousel-item img {
    max-height: 80%;
    margin: 0 10px;
    border-radius: 10px;
  }

  .hair-tips-container img {
    max-height: calc(15rem + 8vh);
  }
}

@media (min-width: 768px) and (orientation: portrait) {
  .gallery-container .carousel .carousel-img-container {
    flex-flow: unset;
  }
}

@media (max-width: 950px) and (max-height: 450px) and (orientation: landscape) {
  .gallery {
    min-height: 100vh;
  }
}

@media (max-width: 767px) and (max-height: 750px) and (orientation: landscape) {
  .gallery-container .carousel .carousel-img-container {
    flex-flow: unset;
  }

  .gallery-container .carousel .carousel-item img {
    max-height: 45%;
  }
}
</style>
